import { useRouter } from 'next/router';
import {
  BreadcrumbJsonLd,
  FAQPageJsonLd,
  LocalBusinessJsonLd,
  NextSeo,
} from 'next-seo';
import React, { useEffect, useState } from 'react';

import CanonicalURL from '@/components/common/Canonical';
import CityComponent from '@/components/store-locator/city-component';
import FacilityComponent from '@/components/store-locator/facility-component';
import {
  generateDynamicBCLD,
  generateDynamicFAQ,
  generateLocalBusinessStructerData,
  getSEOProps,
} from '@/components/utility';
import { cityLanding, facilityPages } from '@/helper/locationPages';
import PageNotFound from '@/pages/404';
import { getValidityCity } from '@/services/home';
import SetBrandsContext from '@/services/home/setBrands';
import { BRAND_NAME_EN, BRAND_NAME_FR } from '@/utils/constants';

const Location = (props: any) => {
  const { query }: any = useRouter();
  const router = useRouter();
  const [facilityPage, setFacilityPage] = useState(false);
  const [cityPage, setCityPage] = useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [cityName, setCityName] = useState('');
  const facilityList = props?.cityData?.data;
  const {
    hostUrl,
    vehicleLandingPage,
    facilityData,
    reviewsData,
    facilityInfo,
    unitsresultsData,
  } = props;
  const [checkQueryLen, setCheckQueryLen] = useState(null);
  const [checkCity, setCheckCity] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    // Handling 404 errors
    if (query && query.location && query.location.length > 2) {
      setError(true);
    } else if (query && query.location && query.location.length === 2) {
      setCheckQueryLen(query.location.length);
      // For Facility pages(single,two)
      const city = query && query.location && query.location[0];
      const name = query && query.location && query.location[1];
      setFacilityPage(true);
      setCityPage(false);
      setCityName(city);
      setFacilityName(name);
    } else if (query && query.location && query.location.length === 1) {
      // For Vehicle pages
      const name1 = query && query.location && query.location[0];
      if (vehicleLandingPage) {
        setCityPage(true);
        setCityName(name1);
        setFacilityPage(false);
      } else {
        setCheckQueryLen(query.location.length);
        const filterLoc = facilityList.length === 0;
        if (filterLoc) {
          // For Facility pages
          setFacilityPage(true);
          setFacilityName(name1);
          setCityPage(false);
        } else {
          // For City pages
          setCityPage(true);
          setCityName(name1);
          setFacilityPage(false);
        }
      }
    }
    if (facilityList?.length === 0) {
      // Boolean value required in facility pages whether single slug or not
      setCheckCity(true);
    } else {
      setCheckCity(false);
    }
  }, [query.location, facilityList]);

  const seoInfo = getSEOProps(props?.seoData, router);

  const faqStructuredData =
    props?.seoFaqData && generateDynamicFAQ(props?.seoFaqData?.Panel, true);
  const breadCrumbUrlList = generateDynamicBCLD(router);
  return (
    <>
      <NextSeo {...seoInfo} />
      {props?.seoFaqData && <FAQPageJsonLd mainEntity={faqStructuredData} />}
      <CanonicalURL />
      <BreadcrumbJsonLd itemListElements={breadCrumbUrlList} />
      {facilityData && unitsresultsData.length > 0 && (
        <>
          <LocalBusinessJsonLd
            {...generateLocalBusinessStructerData(
              facilityData,
              unitsresultsData,
              reviewsData,
              router
            )}
          />
        </>
      )}
      {facilityPage && facilityName && facilityName.length && (
        <>
          <FacilityComponent
            facility={facilityName}
            facilityData={facilityInfo}
            checkCity={checkCity}
            city={cityName}
            reviewsData={reviewsData}
            unitData={unitsresultsData}
            checkQueryLen={checkQueryLen}
            brandData={props?.contextData}
          />
        </>
      )}
      {cityPage && cityName && cityName.length && (
        <CityComponent
          city={cityName}
          hostUrl={hostUrl}
          vehicleLandingPage={vehicleLandingPage}
          brandData={props?.contextData}
        />
      )}
      {error && <PageNotFound />}
    </>
  );
};
export default Location;

export async function getServerSideProps(props: any) {
  const getFacilityCookie = props?.req?.cookies?.facCords;
  const host = props?.req?.headers?.host;
  const locale = props?.locale;
  let defaultBrand = BRAND_NAME_EN;
  const facilityLocation = props?.query?.location;
  let seoData: any = null;
  let slugSEO: any;
  let matchSlugs: any;
  let enSlug: any;
  let frSlug: any;
  let facilityData: any = {};
  let facilityInfo: any = {};
  let seoFaqData: any = null;
  let vehicleLandingPage = false;
  let unitsresultsData: any = [];
  let reviewsData: any = [];
  if (getFacilityCookie) {
    matchSlugs = getFacilityCookie && JSON.parse(getFacilityCookie);
    const [frObj, enObj] = matchSlugs;
    enSlug = enObj && enObj.lan === 'en' ? enObj.slugValue : frObj.slugValue;
    frSlug = frObj && frObj.lan === 'fr' ? frObj.slugValue : enObj.slugValue;
  }
  if (locale === 'fr') {
    defaultBrand = BRAND_NAME_FR;
    if (facilityLocation && facilityLocation.length === 2)
      slugSEO = `${facilityLocation[1]}-fr`;
    else slugSEO = facilityLocation && facilityLocation[0];
  } else
    slugSEO =
      facilityLocation && facilityLocation.length === 1
        ? facilityLocation[0]
        : facilityLocation[1];

  const hostUrl = `${host}/${locale}`;
  let slug = props?.query?.location[0];
  if (slug && slug.substring(slug.length - 3) === '-fr') {
    slug = slug.slice(0, -3);
  }
  const cityRes = await getValidityCity(slug, 'as');
  const cityData = await cityRes?.data;
  if (
    props.query &&
    props.query.location &&
    props.query.location.length === 2
  ) {
    const data = await facilityPages(
      slugSEO,
      defaultBrand,
      locale,
      Object.keys(props.query.location).length,
      enSlug,
      frSlug,
      facilityLocation
    );

    seoData = data.seo || null;
    facilityData = data.facilityDat || null;
    facilityInfo = data.facilityInform || null;
    unitsresultsData = data.unitsresults || null;
    reviewsData = data.reviews || null;
  } else if (
    props.query &&
    props.query.location &&
    props.query.location.length === 1
  ) {
    const filterLoc = cityData && cityData.data && cityData.data.length === 0;
    if (filterLoc) {
      const cityVehicle = await cityLanding(
        slugSEO,
        defaultBrand,
        locale,
        Object.keys(props.query.location).length
      );
      seoData = cityVehicle.seo || null;
      facilityData = cityVehicle.facilityDat || null;
      facilityInfo = cityVehicle.facilityInform || null;
      unitsresultsData = cityVehicle.unitsresults || null;
      reviewsData = cityVehicle.reviews || null;
      seoFaqData = cityVehicle.seoFaq || null;
      vehicleLandingPage = cityVehicle.vehicleLanding || null;
    } else {
      seoData = cityData?.data[0]?.attributes?.SEO || null;
      seoFaqData = cityData?.data[0]?.attributes?.FAQs || null;
    }
  }

  const contextData = await SetBrandsContext(defaultBrand, locale);
  return {
    props: {
      cityData,
      hostUrl,
      seoData,
      vehicleLandingPage,
      seoFaqData,
      facilityData,
      unitsresultsData,
      reviewsData,
      facilityInfo,
      contextData,
    },
  };
}
